import api from "@/services/api";
import { Organization } from "@/services/organization";
import {
  PublicDashboardFilters,
  usePublicDashboardQueryFilters,
} from "@/services/public_dashboard";
import { User } from "@/services/user";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, ComputedRef, inject, reactive } from "vue";
import { IconName } from "@/services/icons";
import {
  MetricDetail,
  metricDetails,
  MetricKey,
  MetricFormat,
  StaticMetricKey,
} from "@/services/metrics";
import * as lodash from "lodash-es";
import { QueryFunctionContext } from "@/utils/typing";
import { WidgetConfig } from "@/services/widgets/index";

export enum MetricType {
  MARKETING = "marketing",
  WEBSITE_ANALYTICS = "website_analytics",
  ECOMMERCE = "ecommerce",
  SOCIAL_MEDIA = "social_media",
  SEARCH_TERM_REPORT = "search_term_report",
}

export enum UnitType {
  NUMBER = "number",
  CURRENCY = "currency",
  PERCENT = "percent",
  TIME = "time",
}

export const CALCULATED_METRIC_PREFIX = "calculatedMetric";
export interface CalculatedMetric {
  pk: number;
  name: string;
  description: string;
  // Original tokenized formula as written by the user, e.g. `["(", 1, "+", 2, ")", "*", 3]`.
  formula: (string | number)[];
  createdAt: string;
  modifiedAt: string;
  metricType: MetricType;
  unitType: UnitType;
  user: User["id"];
  organization: Organization["id"];
}

export const operands = {
  plus: {
    symbol: "+",
    iconName: "plus-operator" as IconName,
  },
  minus: {
    symbol: "-",
    iconName: "minus-operator" as IconName,
  },
  multiply: {
    symbol: "*",
    iconName: "multiply-operator" as IconName,
  },
  divide: {
    symbol: "/",
    iconName: "divide-operator" as IconName,
  },
  exponent: {
    symbol: "^",
    iconName: "exponent-operator" as IconName,
  },
  "parenthesis-left": {
    symbol: "(",
    iconName: "parenthesis-left" as IconName,
  },
  "parenthesis-right": {
    symbol: ")",
    iconName: "parenthesis-right" as IconName,
  },
};

export const LIST_CALCULATED_METRICS_URL =
  "/resources/calculated_metrics/calculated_metrics/";

function listCalculatedMetrics({
  queryKey: [, params],
}: QueryFunctionContext<PublicDashboardFilters>): Promise<CalculatedMetric[]> {
  return api
    .get(LIST_CALCULATED_METRICS_URL, { params })
    .then((response) => response.data);
}

export function useCalculatedMetricsQuery() {
  return reactive(
    useQuery({
      queryKey: [
        "calculatedMetrics",
        { ...usePublicDashboardQueryFilters() },
      ] as const,
      queryFn: listCalculatedMetrics,
    }),
  );
}

export type CreateCalculatedMetricPayload = Pick<
  CalculatedMetric,
  "name" | "description" | "formula" | "metricType" | "unitType"
>;

export function createCalculatedMetric(
  calculatedMetric: CreateCalculatedMetricPayload,
): Promise<CalculatedMetric> {
  return api.post(
    "/resources/calculated_metrics/calculated_metrics/",
    calculatedMetric,
  );
}

export function useCreateCalculatedMetricMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: createCalculatedMetric,
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["calculatedMetrics"],
        });
      },
    }),
  );
}

export type UpdateCalculatedMetricPayload = Pick<
  CalculatedMetric,
  "pk" | "name" | "description" | "formula" | "metricType" | "unitType"
>;

export function updateCalculatedMetric(
  calculatedMetric: UpdateCalculatedMetricPayload,
): Promise<CalculatedMetric> {
  return api.patch(
    `/resources/calculated_metrics/calculated_metrics/${calculatedMetric.pk}/`,
    calculatedMetric,
  );
}

export function useUpdateCalculatedMetricMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: updateCalculatedMetric,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["calculatedMetrics"] });
      },
    }),
  );
}

export function deleteCalculatedMetric(
  pk: CalculatedMetric["pk"],
): Promise<void> {
  return api.delete(`/resources/calculated_metrics/calculated_metrics/${pk}/`);
}

export function useDeleteCalculatedMetricMutation() {
  const queryClient = useQueryClient();
  return reactive(
    useMutation({
      mutationFn: deleteCalculatedMetric,
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["calculatedMetrics"] });
        queryClient.invalidateQueries({
          queryKey: ["profile"],
        });
      },
    }),
  );
}

export function cleanToken(token: string | number): string {
  if (typeof token === "string") {
    const cleanedToken = token.replace(/[{}]/g, "");
    const camelCasedToken = lodash.camelCase(cleanedToken);
    if (metricDetails[camelCasedToken as MetricKey]) {
      return metricDetails[camelCasedToken as MetricKey].shortName;
    }

    return token;
  }
  return token.toString();
}

const unitTypeToMetricFormat: Record<UnitType, MetricFormat> = {
  [UnitType.NUMBER]: MetricFormat.DECIMAL,
  [UnitType.CURRENCY]: MetricFormat.MONETARY,
  [UnitType.PERCENT]: MetricFormat.RELATIVE,
  [UnitType.TIME]: MetricFormat.DURATION,
};

export type CalculatedMetricKey = `${typeof CALCULATED_METRIC_PREFIX}${number}`;

export function calculatedMetricKey(
  calculatedMetric: CalculatedMetric,
): CalculatedMetricKey {
  return `${CALCULATED_METRIC_PREFIX}${calculatedMetric.pk}`;
}

export function isCalculatedMetric(
  metric: string,
): metric is CalculatedMetricKey {
  return metric.startsWith(CALCULATED_METRIC_PREFIX);
}

export type DynamicMetricDetails = Record<StaticMetricKey, MetricDetail> &
  Partial<Record<CalculatedMetricKey, MetricDetail>>;

export function useDynamicMetricDetails() {
  const injectedMetrics = inject<ComputedRef<WidgetConfig | undefined>>(
    "widgetConfig",
    computed(() => undefined),
  );

  const calculatedMetrics = useCalculatedMetricsQuery();

  return computed(() => {
    const dynamicMetricDetails: DynamicMetricDetails = {
      ...metricDetails,
    };

    Object.entries(metricDetails).forEach(([key, detail]) => {
      dynamicMetricDetails[key as StaticMetricKey] = {
        ...detail,
        key: key as StaticMetricKey,
      };
    });

    calculatedMetrics.data?.forEach((calculatedMetric) => {
      const key = calculatedMetricKey(calculatedMetric);
      dynamicMetricDetails[key] = {
        shortName: calculatedMetric.name,
        description: calculatedMetric.description,
        formatType:
          unitTypeToMetricFormat[calculatedMetric.unitType as UnitType],
        summable: false,
        key,
      };
    });

    const storedCalculatedMetrics =
      injectedMetrics?.value?.calculatedMetricInfo;
    if (storedCalculatedMetrics) {
      storedCalculatedMetrics.forEach((metric) => {
        if (metric.key) {
          dynamicMetricDetails[metric.key] = metric;
        }
      });
    }

    return dynamicMetricDetails;
  });
}

export function getCalculatedMetricVariables(
  calculatedMetric: CalculatedMetric,
) {
  return new Set(
    calculatedMetric.formula
      .filter(
        (element): element is string =>
          typeof element === "string" && element.startsWith("{"),
      )
      .map(
        (element) =>
          lodash.camelCase(
            element.replace("{", "").replace("}", ""),
          ) as MetricKey,
      ),
  );
}
