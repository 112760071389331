import { ResourceChannel } from "@/services/resources";
import { draw } from "patternomaly";

const blueShades = [
  "#2A4365",
  "#2C5282",
  "#2B6CB0",
  "#4299E1",
  "#63B3ED",
  "#90CDF4",
  "#BEE3F8",
  // "#3182CE",
  // "#EBF8FF"
];

export function hexToRgb(hex: string): Record<"r" | "g" | "b", number> | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getBlueColorByIndex(number: number): string {
  if (number > blueShades.length) return blueShades[5];
  return blueShades[number];
}

export function getRandomBlueColor(): string {
  return blueShades[Math.floor(Math.random() * blueShades.length)];
}

type TailwindColorShades = Record<number, string>;
type TailwindColors = Record<string, TailwindColorShades>;

export const TAILWIND_COLORS = {
  // Not including grays, black, white, transparent and custom theme colors
  rose: {
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
  },
  violet: {
    50: "#f5f3ff",
    100: "#ede9fe",
    200: "#ddd6fe",
    300: "#c4b5fd",
    400: "#a78bfa",
    500: "#8b5cf6",
    600: "#7c3aed",
    700: "#6d28d9",
    800: "#5b21b6",
    900: "#4c1d95",
  },
  cyan: {
    50: "#ecfeff",
    100: "#cffafe",
    200: "#a5f3fc",
    300: "#67e8f9",
    400: "#22d3ee",
    500: "#06b6d4",
    600: "#0891b2",
    700: "#0e7490",
    800: "#155e75",
    900: "#164e63",
  },
  sky: {
    50: "#f0f9ff",
    100: "#e0f2fe",
    200: "#bae6fd",
    300: "#7dd3fc",
    400: "#38bdf8",
    500: "#0ea5e9",
    600: "#0284c7",
    700: "#0369a1",
    800: "#075985",
    900: "#0c4a6e",
  },
  amber: {
    50: "#fffbeb",
    100: "#fef3c7",
    200: "#fde68a",
    300: "#fcd34d",
    400: "#fbbf24",
    500: "#f59e0b",
    600: "#d97706",
    700: "#b45309",
    800: "#92400e",
    900: "#78350f",
  },
  peach: {
    50: "#FDEFE7",
    100: "#FBE2CF",
    200: "#F7C59F",
    300: "#F5AD89",
    400: "#F49F7E",
    500: "#F28568",
    600: "#F0765C",
    700: "#D86850",
    800: "#C2563F",
    900: "#A02D15",
  },
  purple: {
    50: "#F5F0FF",
    100: "#EFE9FF",
    200: "#E5D7FF",
    300: "#D9C2FF",
    400: "#CCAEFF",
    500: "#BF9AFF",
    600: "#AF81FF",
    700: "#955CF9",
    800: "#7A3AEA",
    900: "#5F1CD5",
  },
  burgundy: {
    50: "#F0EEEE",
    100: "#E2DEDE",
    200: "#BCB3B3",
    300: "#A59A9A",
    400: "#8E8080",
    500: "#786767",
    600: "#624E4E",
    700: "#4B3535",
    800: "#351B1B",
    900: "#1E0202",
  },
  emerald: {
    50: "#ecfdf5",
    100: "#d1fae5",
    200: "#a7f3d0",
    300: "#6ee7b7",
    400: "#34d399",
    500: "#10b981",
    600: "#059669",
    700: "#047857",
    800: "#065f46",
    900: "#064e3b",
  },
  green: {
    50: "#ECFDF5",
    100: "#D1FAE5",
    200: "#A7F3D0",
    300: "#6EE7B7",
    400: "#36DEA1",
    500: "#36DEA1",
    600: "#059669",
    700: "#047857",
    800: "#065F46",
    900: "#064E3B",
  },
  red: {
    50: "#FFF1F2",
    100: "#FFE4E6",
    200: "#FECDD3",
    300: "#FDA4AF",
    400: "#FB7185",
    500: "#F43F5E",
    600: "#E11D48",
    700: "#BE123C",
    800: "#9F1239",
    900: "#881337",
  },
  blue: {
    50: "#E6EEFE",
    100: "#CCDEFD",
    200: "#99BDFC",
    300: "#669CFA",
    400: "#669CFA",
    500: "#4D8BF9",
    600: "#005AF7",
    700: "#1D26FF",
    800: "#1920CE",
    900: "#1218A2",
  },
  turquoise: {
    50: "#E2F8F6",
    100: "#CCF3EF",
    200: "#BEEFEA",
    300: "#A8EAE3",
    400: "#6EDCD0",
    500: "#46D5C6",
    600: "#3DBCAE",
    700: "#1FA295",
    800: "#207970",
    900: "#19655D",
  },
  fuchsia: {
    50: "#FCEDFD",
    100: "#FAE1FB",
    200: "#F9D6FA",
    300: "#F7CAF9",
    400: "#F6C4F8",
    500: "#E6ABE9",
    600: "#D586DC",
    700: "#C76CCF",
    800: "#9F4AA7",
    900: "#861C75",
  },
  pink: {
    50: "#fdf2f8",
    100: "#fce7f3",
    200: "#fbcfe8",
    300: "#f9a8d4",
    400: "#f472b6",
    500: "#ec4899",
    600: "#db2777",
    700: "#be185d",
    800: "#9d174d",
    900: "#831843",
  },
  orange: {
    50: "#FDEDD9",
    100: "#FCE7CC",
    200: "#F9C98D",
    300: "#F7B767",
    400: "#F49F34",
    500: "#EB901E",
    600: "#DC7C03",
    700: "#BD6C06",
    800: "#8C5004",
    900: "#743800",
  },
  lime: {
    50: "#f7fee7",
    100: "#ecfccb",
    200: "#d9f99d",
    300: "#bef264",
    400: "#a3e635",
    500: "#84cc16",
    600: "#65a30d",
    700: "#4d7c0f",
    800: "#3f6212",
    900: "#365314",
  },
  yellow: {
    50: "#FEFCE8",
    100: "#FEF9C3",
    200: "#FEF08A",
    300: "#FDE047",
    400: "#FACC15",
    500: "#EAB308",
    600: "#CF9E06",
    700: "#B98E09",
    800: "#A27D0A",
    900: "#8A6B0C",
  },
  indigo: {
    50: "#EEEFFF",
    100: "#E0E3FF",
    200: "#C7CDFE",
    300: "#A5AEFC",
    400: "#8381F8",
    500: "#6E63F1",
    600: "#6646E5",
    700: "#5637D1",
    800: "#3D348B",
    900: "#2B2468",
  },
  teal: {
    50: "#f0fdfa",
    100: "#ccfbf1",
    200: "#99f6e4",
    300: "#5eead4",
    400: "#2dd4bf",
    500: "#14b8a6",
    600: "#0d9488",
    700: "#0f766e",
    800: "#115e59",
    900: "#134e4a",
  },
};

export const TAILWIND_GRAYS = {
  gray: {
    50: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },
  blueGray: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#E2E8F0",
    300: "#CBD5E1",
    400: "#94A3B8",
    500: "#64748B",
    600: "#475569",
    700: "#334155",
    800: "#1E293B",
    900: "#0F172A",
  },
  coolGray: {
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827",
  },
  trueGray: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#E5E5E5",
    300: "#D4D4D4",
    400: "#A3A3A3",
    500: "#737373",
    600: "#525252",
    700: "#404040",
    800: "#262626",
    900: "#171717",
  },
  warmGray: {
    50: "#FAFAF9",
    100: "#F5F5F4",
    200: "#E7E5E4",
    300: "#D6D3D1",
    400: "#A8A29E",
    500: "#78716C",
    600: "#57534E",
    700: "#44403C",
    800: "#292524",
    900: "#1C1917",
  },
} as const satisfies TailwindColors;
const SHADES_ORDER = [400, 800, 600] as const;

export const TAILWIND_CHART_COLORS: string[] = [];
SHADES_ORDER.forEach((shade) => {
  Object.keys(TAILWIND_COLORS).forEach((color) => {
    TAILWIND_CHART_COLORS.push(
      TAILWIND_COLORS[color as keyof typeof TAILWIND_COLORS][shade],
    );
  });
});

export const TAILWIND_CHART_TRANSPARENT_COLORS: string[] = [];
Object.keys(TAILWIND_COLORS).forEach((color) => {
  TAILWIND_CHART_TRANSPARENT_COLORS.push(
    TAILWIND_COLORS[color as keyof typeof TAILWIND_COLORS][200],
  );
});

export function getTailwindChartColorByIndex(i: number): string {
  return TAILWIND_CHART_COLORS[i % TAILWIND_CHART_COLORS.length];
}
export function getTailwindChartTransparentColorByIndex(i: number): string {
  return TAILWIND_CHART_TRANSPARENT_COLORS[
    i % TAILWIND_CHART_TRANSPARENT_COLORS.length
  ];
}
export function channelPrefersChartColors(channel: ResourceChannel): string[] {
  // const c = TAILWIND_COLORS;
  const colorKey = {
    [ResourceChannel.FACEBOOK_ADS]: "blue",
    [ResourceChannel.FACEBOOK_ORGANIC]: "blue",
    [ResourceChannel.LINKED_IN_ADS]: "indigo",
    [ResourceChannel.LINKED_IN_ORGANIC]: "indigo",
    [ResourceChannel.GOOGLE_ADS]: "peach",
    [ResourceChannel.GOOGLE_ANALYTICS]: "orange",
    [ResourceChannel.GOOGLE_ANALYTICS_4]: "orange",
    [ResourceChannel.GOOGLE_BIG_QUERY]: "blue",
    [ResourceChannel.GOOGLE_BIG_QUERY_ANALYTICS]: "orange",
    [ResourceChannel.GOOGLE_SEARCH_CONSOLE]: "purple",
    [ResourceChannel.YOUTUBE]: "red",
    [ResourceChannel.MERCHANT_CENTER]: "blue",
    [ResourceChannel.DV_360]: "blue", // TODO
    [ResourceChannel.SNAPCHAT_ADS]: "amber",
    [ResourceChannel.INSTAGRAM_ORGANIC]: "fuchsia",
    [ResourceChannel.XANDR]: "red",
    [ResourceChannel.BING_ADS]: "teal",
    [ResourceChannel.C_ALT]: "cyan",
    [ResourceChannel.TIK_TOK_ADS]: "pink",
    [ResourceChannel.TIK_TOK_ORGANIC]: "pink",
    [ResourceChannel.KOBLER]: "yellow",
    [ResourceChannel.ADFORM]: "blue", // TODO
  }[channel] as keyof typeof TAILWIND_COLORS;

  return [
    TAILWIND_COLORS[colorKey][500],
    TAILWIND_COLORS[colorKey][700],
    TAILWIND_COLORS[colorKey][600],
    TAILWIND_COLORS[colorKey][800],
  ];
}

export type DrawPatternType = Parameters<typeof draw>[0];

export const colorBlindPatternKeys: DrawPatternType[] = [
  "plus",
  "cross",
  "dash",
  "cross-dash",
  "dot",
  "dot-dash",
  "disc",
  "ring",
  "line",
  "line-vertical",
  "weave",
  "zigzag",
  "zigzag-vertical",
  "diagonal-right-left",
  "square",
  "box",
  "triangle",
  "triangle-inverted",
  "diamond",
  "diamond-box",
];

export enum Color {
  RED = "red",
  ORANGE = "orange",
  YELLOW = "yellow",
  GREEN = "green",
  TEAL = "teal",
  BLUE = "blue",
  INDIGO = "indigo",
  PURPLE = "purple",
  PINK = "pink",
  GRAY = "gray",
  AMBER = "amber",
  FUCHSIA = "fuchsia",
  ROSE = "rose",
  PEACH = "peach",
  BURGUNDY = "burgundy",
  TURQUOISE = "turquoise",
}
