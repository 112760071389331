import { toRef, computed, Ref } from "vue";
import { AdNetwork } from "@/components/Core/ActionBar/formats";
import {
  RecommendationStatus,
  RECOMMENDATION_STATUSES,
} from "@/services/recommendations/recommendations";
import {
  AvailablePresets,
  COUNTRIES,
  CountryDataFilter,
  DateRangeSelection,
  DateTimeISODateRangeWithPresetLabel,
  payloadToQueryParams,
  SEARCH_TERM_TYPES,
  TimeUnit,
} from "@/services/data_filters";
import { useEventListener, useLocalStorage } from "@vueuse/core";
import { SearchTermDataFilter } from "@/services/data_filters";
import { DateRangePresets } from "@/components/Core/ActionBar/formats";
import {
  Resource,
  ResourceChannel,
  useResourcesQuery,
} from "@/services/resources";
import * as lodash from "lodash-es";
import { COMPETITOR_POSTS_SORT_METRICS } from "@/services/competitor_monitoring";
import { defineStore } from "pinia";
import {
  MarketingMetricSetName,
  MARKETING_METRIC_SET_NAMES,
} from "@/views/MarketingPerformance";
import {
  Analytics4Dimension,
  ANALYTICS_4_METRIC_SETS,
  ANALYTICS_4_METRIC_SET_NAMES,
} from "@/services/website_performance_ga4";
import { MetricFilterSet, MetricKey } from "@/services/metrics";
import { TableSortDirection } from "@/components/DataPresentation";
import { isoStringDayDifference } from "@/utils/dates";
import { FeedItemType } from "@/services/feed";
import { useRouter } from "vue-router";
import { ChangelogEntryType } from "@/services/changelog";
import { Organization } from "@/services/organization";
import { MarketingTableMetricKey } from "@/services/marketing";
import {
  SocialMediaPostSortKey,
  socialMediaPostSortKeys,
} from "@/services/social_media";
import { isCalculatedMetric } from "@/services/calculated_metrics";
import { EcommerceDatapointMetric } from "@/services/ecommerce";
import {
  OrganicSearchTermMetric,
  SearchTermMetric,
} from "@/services/search_terms";

const defaultPresetKey = "last30days";

const availableCountryDataTypes = COUNTRIES;
const availableSearchTermDataTypes = SEARCH_TERM_TYPES;
const availableRecommendationStatuses = RECOMMENDATION_STATUSES;
const availableSocialMediaPostsSortMetrics = socialMediaPostSortKeys;
const availableCompetitorPostsSortMetrics = COMPETITOR_POSTS_SORT_METRICS;
const availableMarketingMetricSets = MARKETING_METRIC_SET_NAMES;
const availableWebsiteAnalyticsMetricSets = ANALYTICS_4_METRIC_SET_NAMES;

export const defaultIncludedCheckboxFilters = {
  includedResources: [] as number[],
  includedTags: [] as number[],
  excludedTags: [] as number[],
  includedChannels: [] as ResourceChannel[],
  includedAdNetworks: [] as AdNetwork[],
  includedConversionGroups: [] as number[],
  includedConversions: [] as number[],
  includedUsers: [] as number[],
  includedFeedItemTypes: [] as FeedItemType[],
  includedCompetitors: [] as (number | null)[],
  includedDomains: [] as string[],
  includedChangelogEntryTypes: [] as ChangelogEntryType[],
  checkedEcommerceBrands: [] as string[],
  checkedEcommerceCategories: [] as string[],
  checkedShareOfSearchCountries: [] as number[],
  checkedShareOfSearchCategories: [] as number[],
  checkedShareOfSearchKeywords: [] as number[],
};
export type IncludedCheckboxFilterKeys =
  keyof typeof defaultIncludedCheckboxFilters;
export type IncludedCheckboxFilterValues =
  (typeof defaultIncludedCheckboxFilters)[IncludedCheckboxFilterKeys];

export const defaultAvailableCheckboxFilters = {
  availableChannels: [] as ResourceChannel[],
};
export const defaultSearchFilters = {
  socialMediaSearchInput: "",
  competitorMonitorSearchInput: "",
  feedItemSearchInput: "",
};
export const defaultListOptionFilters = {
  countryDataType: "total" as CountryDataFilter,
  searchTermDataType: "total" as SearchTermDataFilter,
  recommendationStatus: RecommendationStatus.OPEN as RecommendationStatus,
  socialMediaSortByMetric: "publishedAt" as SocialMediaPostSortKey,
  competitorMonitorSortByMetric:
    "publishedAt" as (typeof COMPETITOR_POSTS_SORT_METRICS)[number],
  marketingMetricSet: "performance" as MarketingMetricSetName,
  websiteAnalyticsMetricSet: "default" as keyof typeof ANALYTICS_4_METRIC_SETS,
  websiteAnalyticsPagesMetricSet:
    "default" as keyof typeof ANALYTICS_4_METRIC_SETS,
};
export const defaultBooleanFilters = {
  separateResources: false,
  metricTotals: false,
  includeAllEvents: false,
};

export const defaultMarketingPageLevelFilters = {
  mpFilterCriteria: [] as MetricFilterSet[],
  mpSelectedColumns: [] as MarketingTableMetricKey[],
};

export const defaultSearchTermPageLevelFilters = {
  stFilterCriteria: [] as MetricFilterSet[],
  stSelectedColumns: [] as SearchTermMetric[],
};

export const defaultSearchTermDetailPageLevelFilters = {
  stDetailPageFilterCriteria: [] as MetricFilterSet[],
  stDetailSearchTermFilterCriteria: [] as MetricFilterSet[],
  stDetailPageSelectedColumns: [] as OrganicSearchTermMetric[],
};

export const defaultWaPagesPageLevelFilters = {
  waPagesTableSearch: "",
  waPagesSelectedMetric: "sessions" as MetricKey,
  waPagesSortMetric: "sessions" as MetricKey | "page",
  waPagesSortDirection: TableSortDirection.DESC,
  waPagesTablePage: 1,
  waPagesTablePageSize: 10,
  waPagesTableCheckedRows: [] as number[],
  waPagesSelectedChart: "bar" as "line" | "bar",
  waPagesStackedChart: true,
  waPagesChartBreakdown: TimeUnit.DAY,
  waPagesSelectedColumns: [] as MetricKey[],
};
export const defaultWaAcquisitionPageLevelFilters = {
  waAcqTableFirstDimension: "source" as Analytics4Dimension,
  waAcqTableSecondDimension: "medium" as Analytics4Dimension | "",
  waAcqTableSearch: "",
  waAcqSelectedMetrics: ["sessions"] as MetricKey[],
  waAcqSortMetric: "sessions" as MetricKey | Analytics4Dimension,
  waAcqSortDirection: TableSortDirection.DESC,
  waAcqTablePage: 1,
  waAcqTablePageSize: 10,
  waAcqSelectedChart: "line" as "line" | "bar",
  waAcqStackedChart: false,
  waAcqChartBreakdown: TimeUnit.DAY,
  waAcqCheckedRows: [] as string[],
  waAcqCompareMetrics: false,
  waAcqSelectedColumns: [] as MetricKey[],
};
export const defaultWaConversionsPageLevelFilters = {
  waConvTableFirstDimension: "source" as Analytics4Dimension,
  waConvTableSecondDimension: "medium" as Analytics4Dimension | "",
  waConvTableSearch: "",
  waConvSelectedMetric: "eventCount" as const,
  waConvSortMetric: "eventCount" as
    | "eventCount"
    | "value"
    | Analytics4Dimension,
  waConvSortDirection: TableSortDirection.DESC,
  waConvTablePage: 1,
  waConvTablePageSize: 10,
  waConvTableCheckedRows: [] as string[],
  waConvTableSelectedColumns: [] as string[],
  waConvSelectedChart: "line" as "line" | "bar",
  waConvStackedChart: false,
  waConvChartBreakdown: TimeUnit.DAY,
  waConvBreakdown: "event" as "all" | "event" | "category",
  waConvDetailSortMetric: "eventCount" as
    | "eventCount"
    | "value"
    | Analytics4Dimension,
  waConvDetailSortDirection: TableSortDirection.DESC,
  waConvDetailTablePage: 1,
  waConvDetailTableCheckedRows: [] as string[],
  waConvDetailStackedChart: false,
  waConvDetailChartBreakdown: TimeUnit.DAY,
  waConvDetailSelectedChart: "line" as "line" | "bar",
};

export const defaultSocialMediaOverviewPageLevelFilters = {
  smFilterCriteria: [] as MetricFilterSet[],
};

export const defaultEcommercePageLevelFilters = {
  ecProductTableFilterCriteria: [] as MetricFilterSet[],
  ecSourceMediumTableFilterCriteria: [] as MetricFilterSet[],
  ecSourceMediumTableSelectedColumns: [] as EcommerceDatapointMetric[],
};
export const defaultCompMonOverviewPageLevelFilters = {
  cmoPostsFilterCriteria: [] as MetricFilterSet[],
};

export const defaultPageLevelFilters = {
  ...defaultMarketingPageLevelFilters,
  ...defaultSearchTermPageLevelFilters,
  ...defaultSearchTermDetailPageLevelFilters,
  ...defaultWaPagesPageLevelFilters,
  ...defaultWaAcquisitionPageLevelFilters,
  ...defaultWaConversionsPageLevelFilters,
  ...defaultSocialMediaOverviewPageLevelFilters,
  ...defaultEcommercePageLevelFilters,
  ...defaultCompMonOverviewPageLevelFilters,
};

export const sharableFilters = {
  ...defaultIncludedCheckboxFilters,
  ...defaultSearchFilters,
  ...defaultListOptionFilters,
  ...defaultBooleanFilters,
  ...defaultPageLevelFilters,
};

// deep copies for the store, to avoid changing the defaults
const sharableFiltersCopy = lodash.cloneDeep(sharableFilters);
const defaultAvailableCheckboxFiltersCopy = lodash.cloneDeep(
  defaultAvailableCheckboxFilters,
);
// const defaultIncludedCheckboxFiltersCopy lodash.cloneDeep(defaultIncludedCheckboxFilters);
// const defaultSearchFiltersCopy lodash.cloneDeep(defaultSearchFilters);
// const defaultListOptionFiltersCopy lodash.cloneDeep(defaultListOptionFilters);
// const defaultBooleanFiltersCopy lodash.cloneDeep(defaultBooleanFilters);

export const useDataFiltersStore = defineStore("dataFilters", {
  state: () => {
    const availablePresets = DateRangePresets();
    const defaultPreset = availablePresets[defaultPresetKey];
    return {
      ...sharableFiltersCopy,
      ...defaultAvailableCheckboxFiltersCopy,
      availablePresets,
      temporalFilter: useLocalStorage("temporal_filter", {
        // TODO: Typeguard
        selection: {
          preset: defaultPreset.label(),
          start: defaultPreset.range().start.toISODate(),
          end: defaultPreset.range().end.toISODate(),
        },
      } as {
        selection: DateTimeISODateRangeWithPresetLabel;
        comparison?: DateTimeISODateRangeWithPresetLabel;
      }),
      isTableComparisonEnabled: useLocalStorage(
        "isTableComparisonEnabled",
        true,
      ),
      singleResourceSelection: useLocalStorage(
        "singleResourceSelection",
        {} as Record<Organization["id"], Resource["pk"]>,
      ),
    };
  },
  actions: {
    setTemporalFilter(filter: DateRangeSelection): void {
      const applying = Object.assign({}, filter);
      this.temporalFilter = applying;
    },
    setTagFilter(included: number[], excluded: number[]): void {
      this.includedTags = included;
      this.excludedTags = excluded;
    },
    includeItem(
      item: Unpacked<IncludedCheckboxFilterValues>,
      array: IncludedCheckboxFilterKeys,
      include: boolean,
    ): void {
      if (include) {
        if (!this[array].includes(item as never)) {
          this[array] = [...this[array], item];
        }
      } else {
        this[array] = (this[array] as never[]).filter(
          (r: typeof item) => r !== item,
        );
      }
    },
    //** resets boolean toggles and string (single list option) filters to their default incase they have been set differently by a malicious share URL */
    typeGuardFilters(): void {
      if (typeof this.separateResources !== "boolean") {
        this.separateResources = false;
      }
      if (typeof this.metricTotals !== "boolean") {
        this.metricTotals = false;
      }
      if (typeof this.includeAllEvents !== "boolean") {
        this.includeAllEvents = false;
      }
      if (!availableCountryDataTypes.includes(this.countryDataType)) {
        this.countryDataType = availableCountryDataTypes[0];
      }
      if (!availableSearchTermDataTypes.includes(this.searchTermDataType)) {
        this.searchTermDataType = availableSearchTermDataTypes[0];
      }
      if (
        !availableRecommendationStatuses.includes(this.recommendationStatus)
      ) {
        this.recommendationStatus = availableRecommendationStatuses[0];
      }
      if (
        !availableSocialMediaPostsSortMetrics.includes(
          this
            .socialMediaSortByMetric as (typeof availableSocialMediaPostsSortMetrics)[number],
        ) &&
        !isCalculatedMetric(this.socialMediaSortByMetric)
      ) {
        this.socialMediaSortByMetric = availableSocialMediaPostsSortMetrics[0];
      }
      if (
        !availableCompetitorPostsSortMetrics.includes(
          this.competitorMonitorSortByMetric,
        )
      ) {
        this.competitorMonitorSortByMetric =
          availableCompetitorPostsSortMetrics[0];
      }
      if (!availableMarketingMetricSets.includes(this.marketingMetricSet)) {
        this.marketingMetricSet = availableMarketingMetricSets[0];
      }
      if (
        !availableWebsiteAnalyticsMetricSets.includes(
          this.websiteAnalyticsMetricSet,
        )
      ) {
        this.websiteAnalyticsMetricSet = availableWebsiteAnalyticsMetricSets[0];
      }
    },
    sharableFilterIsDefaultValue(key: keyof typeof sharableFilters): boolean {
      return lodash.isEqual(this.$state[key], sharableFilters[key]);
    },
    setFilterToDefaultValue(key: keyof typeof sharableFilters): void {
      if (key in sharableFilters) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.$state[key] = sharableFilters[key];
      }
    },

    initializeDataFilters({
      availableChannels,
    }: {
      availableChannels?: Readonly<ResourceChannel[]>;
    } = {}): void {
      this.typeGuardFilters();
      if (availableChannels) {
        this.availableChannels = [...availableChannels];
        this.includedChannels = this.includedChannels.filter((r) =>
          availableChannels.includes(r),
        );
      }
    },
    resetSharableFilters(): void {
      this.$patch(
        lodash.cloneDeep(
          lodash.pick(
            sharableFilters,
            ...Object.keys(
              lodash.omit(
                sharableFilters, // don't reset page level filters, and list options except search term data type
                ...Object.keys(defaultListOptionFilters),
                ...Object.keys(defaultPageLevelFilters),
              ),
            ),
            "searchTermDataType",
          ),
        ),
      );
    },
    updateTemporalFilter(): void {
      // This code updates the daterange selection if a preset is selected but the localStorage date
      // selection doesn't match the preset's daterange. Basically, if a user selects "This month", and
      // is browsing the app at Jan 4th 23:59, then this code will make sure that if the user F5's
      // after the clock hits Jan 5th, then their new daterange will also include Jan 4th
      // TODO: Make presets react to the current date changing
      this.availablePresets = DateRangePresets();
      if (this.temporalFilter.selection.preset !== "Custom") {
        const matchingPresetKey = lodash.findKey(this.availablePresets, (e) => {
          return e.label() === this.temporalFilter.selection.preset;
        }) as keyof AvailablePresets;
        if (matchingPresetKey) {
          const { start, end } =
            this.availablePresets[matchingPresetKey].range();
          if (
            start.toISODate() !== this.temporalFilter.selection.start ||
            end.toISODate() !== this.temporalFilter.selection.end
          ) {
            const comparisonPresets =
              this.availablePresets[matchingPresetKey].comparisonPresets;
            const matchingComparisonPreset = Object.values(
              comparisonPresets,
            ).find((b) => {
              return b.label === this.temporalFilter.comparison?.preset;
            });
            if (
              this.temporalFilter.comparison &&
              this.temporalFilter.comparison?.preset === "Personalized"
            ) {
              this.setTemporalFilter({
                selection: {
                  preset: this.temporalFilter.selection.preset,
                  start: start.toISODate(),
                  end: end.toISODate(),
                },
                comparison: this.temporalFilter.comparison,
              });
            } else {
              this.setTemporalFilter({
                selection: {
                  preset: this.temporalFilter.selection.preset,
                  start: start.toISODate(),
                  end: end.toISODate(),
                },
                ...(matchingComparisonPreset && {
                  comparison: {
                    preset: matchingComparisonPreset.label,
                    start: matchingComparisonPreset.range().start.toISODate(),
                    end: matchingComparisonPreset.range().end.toISODate(),
                  },
                }),
              });
            }
          }
        }
      }
    },
  },
  getters: {
    temporalFilterSelectionInDays(): number {
      return isoStringDayDifference(
        this.temporalFilter.selection.start,
        this.temporalFilter.selection.end,
      );
    },
  },
});

function useFullDataFilters() {
  const dataFiltersStore = useDataFiltersStore();
  return {
    channel: {
      channel__in: computed(() => dataFiltersStore.includedChannels),
    },
    includedResources: {
      resource__in: computed(() => dataFiltersStore.includedResources),
    },
    selectionTemporalFilter: {
      selection_from: computed(
        () => dataFiltersStore.temporalFilter.selection.start,
      ),
      selection_to: computed(
        () => dataFiltersStore.temporalFilter.selection.end,
      ),
    },
    temporalFilter: {
      selection_from: computed(
        () => dataFiltersStore.temporalFilter.selection.start,
      ),
      selection_to: computed(
        () => dataFiltersStore.temporalFilter.selection.end,
      ),
      comparison_from: computed(
        () => dataFiltersStore.temporalFilter.comparison?.start ?? null,
      ),
      comparison_to: computed(
        () => dataFiltersStore.temporalFilter.comparison?.end ?? null,
      ),
    },
    includedTags: {
      tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedTags: {
      not__tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedAccountTags: {
      account_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedAccountTags: {
      not__account_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedCampaignTags: {
      campaign_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedCampaignTags: {
      not__campaign_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedAdGroupTags: {
      ad_group_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedAdGroupTags: {
      not__ad_group_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedAdTags: {
      ad_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedAdTags: {
      not__ad_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedKeywordTags: {
      keyword_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedKeywordTags: {
      not__keyword_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    includedSearchTermTags: {
      query_tags__in: computed(() => dataFiltersStore.includedTags),
    },
    excludedSearchTermTags: {
      not__query_tags__in: computed(() => dataFiltersStore.excludedTags),
    },
    subcategory: {
      subcategory__in: computed(() => dataFiltersStore.includedAdNetworks),
    },
    conversionGroup: {
      conversion_group__in: computed(
        () => dataFiltersStore.includedConversionGroups,
      ),
    },
    conversion: {
      conversion__in: computed(() => dataFiltersStore.includedConversions),
    },
    brands: { brand: computed(() => dataFiltersStore.checkedEcommerceBrands) },
    categories: {
      category: computed(() => dataFiltersStore.checkedEcommerceCategories),
    },
    domains: {
      domain__in: computed(() => dataFiltersStore.includedDomains),
    },
    separateResources: {
      separateResources: toRef(dataFiltersStore, "separateResources"),
    },
    includeAllEvents: {
      is_conversion_event: computed(() =>
        dataFiltersStore.includeAllEvents ? null : true,
      ),
    },
    includedUsers: {
      user__in: computed(() => dataFiltersStore.includedUsers),
      created_by__in: computed(() => dataFiltersStore.includedUsers),
    },
    includedFeedItemTypes: {
      item_type__in: computed(() => dataFiltersStore.includedFeedItemTypes),
    },
    feedItemSearchInput: {
      search: computed(() => dataFiltersStore.feedItemSearchInput),
    },
    shareOfSearchCompetitors: {
      competitors: computed(
        () =>
          dataFiltersStore.includedCompetitors.map((pk) => pk ?? "null") as (
            | number
            | null
          )[],
      ),
    },
    shareOfSearchCategories: {
      categories: toRef(dataFiltersStore, "checkedShareOfSearchCategories"),
    },
    shareOfSearchCountries: {
      countries: toRef(dataFiltersStore, "checkedShareOfSearchCountries"),
    },
    shareOfSearchKeywords: {
      keywords: toRef(dataFiltersStore, "checkedShareOfSearchKeywords"),
    },
  };
}

// The reason for the complexity in this function is that it picks keys
// based on the outer keys in useFullDataFilters (e.g. includedTags),
// but it produces an object with keys based on the inner keys in
// useFullDataFilters (e.g. tags__in).
export function useDataFilters<
  K extends keyof ReturnType<typeof useFullDataFilters>,
>(
  keys: K[],
  // When false this function is essentially a no-op.
  // This is used to avoid including "global" (action bar) filters in certain cases,
  // such as dashboards.
  useGlobalFilters = true,
) {
  const fullDataFilters = useFullDataFilters();
  const dataFilters = Object.fromEntries(
    Object.values(lodash.pick(fullDataFilters, keys))
      .flatMap((obj) => Object.entries(obj as Record<string, Ref>))
      .map(([k, v]) => [k, computed(() => payloadToQueryParams(v.value))]),
  ) as UnionToIntersection<ReturnType<typeof useFullDataFilters>[K]>;
  if (useGlobalFilters) {
    return dataFilters;
  } else {
    return Object.fromEntries(
      //@ts-expect-error ...
      Object.keys(dataFilters).map((k) => [k, computed(() => null)]),
    ) as typeof dataFilters;
  }
}

export function useCalculatedResources(
  selectedResourceIds?: Ref<number[]>,
  useGlobalFilters = true,
): {
  resource__in: Ref<number[] | null>;
} {
  return selectedResourceIds
    ? {
        resource__in: computed(
          () =>
            payloadToQueryParams(selectedResourceIds.value) as number[] | null,
        ),
      }
    : useDataFilters(["includedResources"], useGlobalFilters);
}
export function useConversionGroups(
  conversionGroups?: Ref<number[]>,
  useGlobalFilters = true,
): {
  conversion_group__in: Ref<number[] | null>;
} {
  return conversionGroups
    ? {
        conversion_group__in: computed(
          () => payloadToQueryParams(conversionGroups.value) as number[] | null,
        ),
      }
    : useDataFilters(["conversionGroup"], useGlobalFilters);
}

export function useConversions(
  conversions?: Ref<number[]>,
  useGlobalFilters = true,
): {
  conversion__in: Ref<number[] | null>;
} {
  return conversions
    ? {
        conversion__in: computed(
          () => payloadToQueryParams(conversions.value) as number[] | null,
        ),
      }
    : useDataFilters(["conversion"], useGlobalFilters);
}

export function useDetachedTemporalFilter(
  temporalFilter?: Ref<DateRangeSelection>,
  useGlobalFilters = true,
): {
  selection_from: Ref<string>;
  selection_to: Ref<string>;
  comparison_from: Ref<string | null>;
  comparison_to: Ref<string | null>;
} {
  return temporalFilter
    ? {
        selection_from: computed(() => temporalFilter.value.selection.start),
        selection_to: computed(() => temporalFilter.value.selection.end),
        comparison_from: computed(
          () => temporalFilter.value.comparison?.start ?? null,
        ),
        comparison_to: computed(
          () => temporalFilter.value.comparison?.end ?? null,
        ),
      }
    : useDataFilters(["temporalFilter"], useGlobalFilters);
}

export function useDetachedSelectionTemporalFilter(
  temporalFilter?: Ref<DateRangeSelection>,
  useGlobalFilters = true,
): {
  selection_from: Ref<string>;
  selection_to: Ref<string>;
} {
  return temporalFilter
    ? {
        selection_from: computed(() => temporalFilter.value.selection.start),
        selection_to: computed(() => temporalFilter.value.selection.end),
      }
    : useDataFilters(["selectionTemporalFilter"], useGlobalFilters);
}

export function useDetachedTagFilter(
  useGlobalFilters = true,
  includedTags?: Ref<number[]>,
  excludedTags?: Ref<number[]>,
): {
  tags__in: Ref<number[] | null>;
  not__tags__in: Ref<number[] | null>;
} {
  return includedTags
    ? {
        tags__in: computed(
          () => payloadToQueryParams(includedTags.value) as number[] | null,
        ),
        not__tags__in: computed(
          () =>
            payloadToQueryParams(excludedTags?.value ?? []) as number[] | null,
        ),
      }
    : useDataFilters(["includedTags", "excludedTags"], useGlobalFilters);
}

export function useShareOfSearchCategoriesFilter(categories?: Ref<number[]>): {
  categories: Ref<number[] | undefined>;
} {
  return categories
    ? {
        categories,
      }
    : useDataFilters(["shareOfSearchCategories"]);
}
export function useShareOfSearchCountriesFilter(countries?: Ref<number[]>): {
  countries: Ref<number[] | undefined>;
} {
  return countries
    ? {
        countries,
      }
    : useDataFilters(["shareOfSearchCountries"]);
}
export function useShareOfSearchKeywordsFilter(keywords?: Ref<number[]>): {
  keywords: Ref<number[] | undefined>;
} {
  return keywords
    ? {
        keywords,
      }
    : useDataFilters(["shareOfSearchKeywords"]);
}
export function useShareOfSearchCompetitorsFilter(
  competitors?: Ref<number[]>,
): {
  competitors: Ref<(number | null)[] | undefined>;
} {
  return competitors
    ? {
        competitors,
      }
    : useDataFilters(["shareOfSearchCompetitors"]);
}

export function useUpdateTemporalFilter() {
  const dataFiltersStore = useDataFiltersStore();

  useEventListener(window, "focus", dataFiltersStore.updateTemporalFilter);

  const router = useRouter();
  router.beforeEach(dataFiltersStore.updateTemporalFilter);
}

export function useAvailableResources() {
  const resources = useResourcesQuery();
  const dataFiltersStore = useDataFiltersStore();
  return computed(
    () =>
      resources?.data?.filter((resource) =>
        dataFiltersStore.availableChannels.includes(resource.channel),
      ) ?? [],
  );
}
