import { IconName } from "@/services/icons";
import { Organization } from "@/services/organization";
import {
  formatCurrency,
  formatCurrencyCompact,
} from "@/utils/filters/currencies";
import { formatDuration } from "@/utils/filters/duration";
import { formatNumber, formatNumberCompact } from "@/utils/filters/numbers";
import { assertUnreachable } from "@/utils/typing";
import {
  CalculatedMetricKey,
  DynamicMetricDetails,
} from "@/services/calculated_metrics";
import { upperFirst } from "lodash-es";

export enum MetricFormat {
  MONETARY = "monetary",
  RELATIVE = "relative",
  INTEGER = "integer",
  DECIMAL = "decimal",
  DURATION = "duration",
  STATE = "state",
}

export enum MetricFilterRelationships {
  EQUAL_TO = "equal to",
  LESS_THAN = "less than",
  LESS_THAN_OR_EQUAL_TO = "less than or equal to",
  GREATER_THAN_OR_EQUAL_TO = "greater than or equal to",
  GREATER_THAN = "greater than",
  NOT_EQUAL_TO = "not equal to",
}

export interface MetricDetail {
  key?: MetricKey;
  longName?: string;
  shortName: string;
  description?: string;
  formatType: MetricFormat;
  icon?: IconName;
  summable?: boolean;
  invertedPositive?: boolean; // for cost metrics
  invertedDirection?: boolean;
  diffType?: "absolute" | "relative";
}

const _metricDetails = {
  addToCarts: {
    formatType: MetricFormat.INTEGER,
    shortName: "Add-to-carts",
    icon: "shopping-cart",
    description: "",
  },
  checkouts: {
    formatType: MetricFormat.INTEGER,
    shortName: "Checkouts",
    icon: "arrow-alt-from-left",
    description: "",
  },
  purchases: {
    formatType: MetricFormat.INTEGER,
    shortName: "Purchases",
    icon: "money-bill-wave",
    description: "",
  },
  clicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Clicks",
    icon: "mouse-pointer",
    description:
      "The number of clicks on links within the ad that led to advertiser-specified destinations, on or off the third party channel.",
  },
  totalClicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Clicks (All)",
    icon: "mouse-pointer",
    description: "",
  },
  impressions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Impressions",
    icon: "eye",
    description: "",
  },
  shares: {
    formatType: MetricFormat.INTEGER,
    shortName: "Shares",
    icon: "link",
    description: "",
  },
  comments: {
    formatType: MetricFormat.INTEGER,
    shortName: "Comments",
    icon: "comment-dots",
    description: "",
  },
  reactions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Reactions",
    icon: "thumbs-up",
    description: 'The count of "reacts" (like, love, sad, etc.) on a post.',
  },
  sessions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Sessions",
    description: "",
  },
  engagedSessions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Engaged sessions",
    description:
      "Sessions that had a conversion event, had 2 or more page views, \n or lasted at least 10 seconds (you can define up to \n 60 seconds in Google Analytics 4).",
  },
  users: {
    formatType: MetricFormat.INTEGER,
    shortName: "Total users",
    icon: "users",
    description: "",
    summable: false,
  },
  newUsers: {
    formatType: MetricFormat.INTEGER,
    shortName: "New users",
    icon: "user-plus",
    description: "Users who visited your site for the first time.",
  },
  followers: {
    formatType: MetricFormat.INTEGER,
    shortName: "Followers",
    icon: "users",
    description: "",
  },
  followersGrowth: {
    formatType: MetricFormat.INTEGER,
    shortName: "Followers Growth",
    icon: "users",
    description: "",
  },
  pageViews: {
    formatType: MetricFormat.INTEGER,
    shortName: "Page views",
    icon: "eye",
    description: "",
  },
  pageViewsPerUser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Page views/total users",
    longName: "Page views per total users",
    icon: "eye",
    description: "",
    summable: false,
  },
  pageViewsPerActiveUser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Page views/active users",
    longName: "Page views per active users",
    icon: "eye",
    description: "",
    summable: false,
  },
  posts: {
    formatType: MetricFormat.INTEGER,
    shortName: "Posts",
    description: "",
  },
  pagesPerSession: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Pages/session",
    longName: "Pages per session",
    description: "",
    summable: false,
  },
  engagement: {
    formatType: MetricFormat.INTEGER,
    shortName: "Engagement",
    icon: "heartbeat-solid",
    description:
      "Engangement is the sum of actions performed on your organic post or ad. \n Depending on platform, it can for example be the sum of reactions, shares, \n comments, video plays, page likes and link clicks.",
  },
  transactions: {
    formatType: MetricFormat.INTEGER,
    icon: "handshake",
    shortName: "Transactions",
    description: "",
  },
  lighthouseScore: {
    formatType: MetricFormat.INTEGER,
    shortName: "Lighthouse score",
    description: "",
  },
  qualityScore: {
    formatType: MetricFormat.INTEGER,
    shortName: "Quality score",
    icon: "certificate-solid",
    description:
      "An estimate of the relevance of your ads, keywords and landing pages to people seeing your ads.",
  },
  conversions: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Conversions",
    description:
      "Sourced from the ad network. For Google Ads it is Conversions (by conv. time).",
    icon: "sync-alt",
  },
  conversionValue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Conversion value",
    description: "",
    icon: "money-bill-wave",
  },
  totalConversions: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Total conversions",
    description: "",
  },
  eventCount: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Event count",
    longName: "Conversions and event count",
    description: "",
    icon: "sync-alt",
  },
  avgPosition: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Avg. position",
    longName: "Average position",
    description:
      "The average position in the Google Search Engine Results Page \n (SERP), 1 being the highest",
    summable: false,
    invertedPositive: true,
    invertedDirection: true,
    icon: "list-ol",
  },
  averagePosition: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Avg. position",
    longName: "Average position",
    description:
      "The average position in the Google Search Engine Results Page \n (SERP), 1 being the highest",
    summable: false,
    invertedPositive: true,
    invertedDirection: true,
    icon: "list-ol",
  },
  postsPerDay: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Posts/day",
    longName: "Posts per day",
    description: "",
  },
  averageMonthlySearches: {
    formatType: MetricFormat.INTEGER,
    shortName: "Searches/month",
    longName: "Searches per month",
    description: "",
  },
  pageViewsPerSession: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Page views/session",
    longName: "Page views per session",
    description:
      "The number of app screens or web pages your users viewed per session. Repeated views of a single page or screen are counted. (screen_view + page_view events) / sessions.",
    summable: false,
  },
  conversionsPerImpression: {
    formatType: MetricFormat.DECIMAL,
    shortName: "CPI",
    longName: "Conversions per impression",
    description: "Conversions per impression.",
    summable: false,
  },
  purchaseRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Purchase revenue",
    icon: "money-bill-wave",
    description: "",
  },
  value: {
    formatType: MetricFormat.MONETARY,
    shortName: "Value",
    icon: "money-bill-wave",
    description: "",
  },
  budget: {
    formatType: MetricFormat.MONETARY,
    icon: "coins",
    shortName: "Budget",
  },
  cost: {
    formatType: MetricFormat.MONETARY,
    icon: "coins",
    shortName: "Cost",
    description:
      "The total cost of running an account, campaign, ad or similar.",
    invertedPositive: true,
  },
  costPerClick: {
    formatType: MetricFormat.MONETARY,
    shortName: "CPC",
    longName: "Cost per click",
    description: "The total cost per click an ad recieves.",
    summable: false,
    invertedPositive: true,
  },
  costPerAction: {
    formatType: MetricFormat.MONETARY,
    shortName: "CPA",
    longName: "Cost per action",
    description:
      "Cost per action, or cost per acquisition (CPA) \n measures how much it costs to generate an action.",
    summable: false,
    invertedPositive: true,
    icon: "coins",
  },
  costPerConversion: {
    formatType: MetricFormat.MONETARY,
    shortName: "Cost/conversion",
    longName: "Cost per conversion",
    description: "",
    summable: false,
    invertedPositive: true,
    icon: "coins",
  },
  costPerMilli: {
    formatType: MetricFormat.MONETARY,
    shortName: "CPM",
    longName: "Cost per 1000 impressions",
    description: "Cost per mille (CPM) is the cost per 1000 impressions.",
    summable: false,
    invertedPositive: true,
    icon: "coins",
  },
  returnOnAdSpend: {
    formatType: MetricFormat.RELATIVE,
    shortName: "ROAS%",
    longName: "Return on ad spend (percentage)",
    description:
      "Return on ad spend measures how much revenue you receive compared to how much you spend on advertising. It is calculated as value divided by cost, multiplied by 100.",
    summable: false,
  },
  returnOnAdSpendRatio: {
    formatType: MetricFormat.DECIMAL,
    shortName: "ROAS",
    longName: "Return on ad spend (ratio)",
    description:
      "Return on ad spend measures how much revenue you receive compared to how much you spend on advertising. It is calculated as value divided by cost.",
    summable: false,
  },
  revenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Revenue",
    description: "",
  },
  averageOrderValue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Avg. order value",
    longName: "Average order value",
    description: "",
    summable: false,
  },
  maxCostPerClick: {
    formatType: MetricFormat.MONETARY,
    shortName: "Max CPC",
    longName: "Max cost per click",
    description: "The max amount you will pay for an ad click.",
  },
  estimatedFirstPositionBid: {
    formatType: MetricFormat.MONETARY,
    shortName: "Estimated first position bid",
    description:
      "An estimate of the amount to bid for your ad to be shown first in Google search results.",
    summable: false,
    invertedPositive: true,
  },
  avgBounceRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Avg. bounce rate",
    longName: "Average bounce rate",
    description:
      "Bounce rate is the percentage of visitors that leave a webpage while not having performed an action on the page.",
    summable: false,
    invertedPositive: true,
  },
  engagementRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Engagement rate",
    description:
      "In SoMe, this metric measures engagement (number of actions on a post or ad) divided by number of impressions. \n In Google Analytics 4, it measures engaged sessions divided by sessions",
    summable: false,
  },
  likes: {
    formatType: MetricFormat.INTEGER,
    shortName: "Likes",
    description: "",
    icon: "thumbs-up",
  },
  clickthroughRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "CTR",
    longName: "Clickthrough rate",
    description:
      "Clickthrough rate (CTR) is the number of clicks divided by the number of impressions.",
    summable: false,
    icon: "hand-pointer",
  },
  conversionRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Conversion rate",
    description: "",
    summable: false,
  },
  clickRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Click rate",
    description: "",
    icon: "hand-pointer",
    summable: false,
  },
  bounceRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Bounce rate",
    description: "",
    summable: false,
    invertedPositive: true,
  },
  followerGrowth: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Follower growth",
    icon: "user-plus",
    description: "",
    summable: false,
  },
  avgTimeOnPage: {
    formatType: MetricFormat.DURATION,
    shortName: "Avg. time on page",
    longName: "Average time on page",
    description: "",
    summable: false,
    icon: "stopwatch",
  },
  avgSessionDuration: {
    formatType: MetricFormat.DURATION,
    shortName: "Avg. session duration",
    longName: "Average session duration",
    description: "",
    summable: false,
    icon: "stopwatch",
  },
  engagementTimePerSession: {
    formatType: MetricFormat.DURATION,
    shortName: "Engagement time/session",
    longName: "Engagement time per session",
    description: "",
    summable: false,
  },
  engagementTimePerPage: {
    formatType: MetricFormat.DURATION,
    shortName: "Engagement time/page",
    longName: "Engagement time per page",
    description: "",
    summable: false,
  },
  competition: {
    formatType: MetricFormat.STATE,
    shortName: "Competition",
    description: "",
    icon: "award-solid",
  },
  expectedClickthroughRate: {
    formatType: MetricFormat.STATE,
    shortName: "Expected CTR",
    longName: "Expected clickthrough rate",
    icon: "hand-pointer",
    description:
      "An estimated measure of the likelihood of people clicking your ad.",
  },
  landingPageExperience: {
    formatType: MetricFormat.STATE,
    icon: "browser",
    shortName: "Landing page experience",
    description:
      "An estimate of the usefulness of your landing page to users clicking on your ad.",
  },
  quantity: {
    formatType: MetricFormat.INTEGER,
    shortName: "Quantity",
    description: "",
  },
  averagePrice: {
    formatType: MetricFormat.MONETARY,
    shortName: "Average Price",
    description: "",
    summable: false,
  },
  postsPublished: {
    formatType: MetricFormat.INTEGER,
    shortName: "Posts Published",
    description: "",
  },
  initialVideoViews: {
    formatType: MetricFormat.INTEGER,
    shortName: "Initial video views",
    description: `2 or 3 second video view, depending on the channel. 
    2 seconds for Snapchat, TikTok, LinkedIn and Microsoft Ads, 3 seconds for Meta. LinkedIn also counts call-to-action clicks. Other channels do not have this metric.`,
    icon: "video",
  },
  videoViews25Pct: {
    formatType: MetricFormat.INTEGER,
    shortName: "25% video views",
    description: "The number of times at least 25% of the video was watched.",
    icon: "video",
  },
  videoViews50Pct: {
    formatType: MetricFormat.INTEGER,
    shortName: "50% video views",
    description: "The number of times at least 50% of the video was watched.",
    icon: "video",
  },
  videoViews75Pct: {
    formatType: MetricFormat.INTEGER,
    shortName: "75% video views",
    description: "The number of times at least 75% of the video was watched.",
    icon: "video",
  },
  videoViews100Pct: {
    formatType: MetricFormat.INTEGER,
    shortName: "Video completions",
    description: "The number of times the entire video was watched.",
    icon: "video",
  },
  metaThruplays: {
    formatType: MetricFormat.INTEGER,
    shortName: "Thruplays",
    description:
      "Meta Ads specific. For videos shorter than 15 seconds, thruplays are counted when the video is played to completion. Longer videos must be played for at least 15 seconds.",
    icon: "video",
  },
  youtubeViews: {
    formatType: MetricFormat.INTEGER,
    shortName: "Youtube views",
    description: `Google Ads specific. This is counted differently based on the type of video ad.
    
      For skippable in-stream ads, it is a 30-second watch (or until the end if it's shorter). For in-feed video ads, the user must click the thumbnail or watch at least 10 seconds (or until the end if it's shorter). For Shorts ads, users must click the call-to-action button or watch at least 10 seconds (or until the end if shorter).`,
    icon: "video",
  },
  averageVideoWatchTime: {
    formatType: MetricFormat.DURATION,
    shortName: "Average video watch time",
    description: "The average duration the video was watched per play.",
    icon: "video",
    summable: false,
  },
  costPerInitialVideoView: {
    formatType: MetricFormat.MONETARY,
    shortName: "Cost/initial video view",
    longName: "Cost per initial video view.",
    description:
      "Calculated as total cost divided by initial video views. Note that this will include cost for ads not eligible for video views.",
    icon: "video",
    summable: false,
  },
  costPerMetaThruplay: {
    formatType: MetricFormat.MONETARY,
    shortName: "Cost/thruplay",
    longName: "Cost per thruplay",
    description:
      "Calculated as total cost divided by thruplays. Note that this will include cost for ads not eligible for thruplays.",
    icon: "video",
    summable: false,
  },
  youtubeViewRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Youtube view rate",
    description:
      "Google Ads specific. Youtube views divided by the number of impressions on ads that are eligible to receive views.",
    icon: "video",
    summable: false,
  },
  activeUsers: {
    formatType: MetricFormat.INTEGER,
    shortName: "Active users",
    description: "The number of distinct users who visited your site or app.",
    summable: false,
  },
  adUnitExposure: {
    formatType: MetricFormat.INTEGER,
    shortName: "Ad unit exposure",
    description:
      "The time that an ad unit was exposed to a user, in milliseconds.",
  },
  adsClicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Ads clicks",
    description:
      "Total number of times users have clicked on an ad to reach the property. Includes clicks from linked integrations like linked Search Ads 360 advertisers. Also includes uploaded clicks from data import.",
  },
  adsCost: {
    formatType: MetricFormat.MONETARY,
    shortName: "Ads cost",
    description:
      "The total amount you paid for your ads. Includes costs from linked integrations like linked Google Ads accounts. Also includes uploaded cost from data import.",
  },
  adsCostPerClick: {
    formatType: MetricFormat.MONETARY,
    shortName: "Ads cost per click",
    description:
      "Ads cost per click is ad cost divided by ad clicks and is often abbreviated CPC.",
    summable: false,
  },
  adsImpressions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Ads impressions",
    description:
      "The total number of impressions. Includes impressions from linked integrations like linked Display & Video 360 advertisers. Also includes uploaded impressions from data import.",
  },
  averagePurchaseRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Average purchase revenue",
    description:
      "The average purchase revenue in the transaction group of events.",
    summable: false,
  },
  averagePurchaseRevenuePerPayingUser: {
    formatType: MetricFormat.MONETARY,
    shortName: "ARPPU",
    longName: "Average revenue per paying user",
    description:
      "Average revenue per paying user (ARPPU) is the total purchase revenue per active user that logged a purchase event.",
    summable: false,
  },
  averagePurchaseRevenuePerUser: {
    formatType: MetricFormat.MONETARY,
    shortName: "Average purchase revenue per user",
    description:
      "The average purchase revenue per active user is the total purchase revenue per active user that logged any event.",
    summable: false,
  },
  averageRevenuePerUser: {
    formatType: MetricFormat.MONETARY,
    shortName: "ARPU",
    longName: "Average revenue per user",
    description:
      "Average revenue per active user (ARPU). ARPU uses Total Revenue and includes AdMob estimated earnings.",
    summable: false,
  },
  averageSessionDuration: {
    formatType: MetricFormat.DURATION,
    shortName: "Average session duration",
    description: "The average duration of users' sessions.",
    summable: false,
  },
  cartToViewRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Cart-to-view rate",
    description:
      "The number of users who added a product(s) to their cart divided by the number of users who viewed the same product(s).",
    summable: false,
  },
  crashAffectedUsers: {
    formatType: MetricFormat.INTEGER,
    shortName: "Crash-affected users",
    description:
      'The number of users that logged a crash in this row of the report. For example if the report is time series by date, this metrics reports total users with at least one crash on this date. Crashes are events with the name "app_exception".',
  },
  crashFreeUsersRate: {
    formatType: MetricFormat.INTEGER,
    shortName: "Crash-free users rate",
    description:
      "The number of users without crash events divided by the total number of users.",
    summable: false,
  },
  dauPerMau: {
    formatType: MetricFormat.RELATIVE,
    shortName: "DAU / MAU",
    description:
      "The rolling percent of 30-day active users who are also 1-day active users.",
    summable: false,
  },
  dauPerWau: {
    formatType: MetricFormat.RELATIVE,
    shortName: "DAU / WAU",
    description:
      "The rolling percent of 7-day active users who are also 1-day active users.",
    summable: false,
  },
  eventCountPerUser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Event count per user",
    description:
      "The average number of events per user (Event count divided by Active users).",
    summable: false,
  },
  eventsPerSession: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Events per session",
    description:
      "The average number of events per session (Event count divided by Sessions).",
    summable: false,
  },
  firstTimePurchaserRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "First-time purchaser rate",
    description:
      "The percentage of active users who made their first purchase.",
    summable: false,
  },
  firstTimePurchasers: {
    formatType: MetricFormat.INTEGER,
    shortName: "First-time purchasers",
    description:
      "The number of users that completed their first purchase event.",
  },
  firstTimePurchasersPerNewUser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "First-time purchasers per new user",
    description: "The average number of first time purchasers per new user.",
  },
  grossItemRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Gross item revenue",
    description:
      "The total revenue from items only. Gross item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level. Gross item revenue does not include refunds.",
  },
  grossPurchaseRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Gross purchase revenue",
    description:
      "The sum of revenue from purchases made in your app or site. Gross purchase revenue sums the revenue for these events: purchase, ecommerce_purchase, in_app_purchase, app_store_subscription_convert, and app_store_subscription_renew. Purchase revenue is specified by the value parameter in tagging.",
  },
  itemDiscountAmount: {
    formatType: MetricFormat.MONETARY,
    shortName: "Item discount amount",
    description:
      "The monetary value of item discounts in eCommerce events. This metric is populated in tagging by the discount item parameter.",
  },
  itemListClickEvents: {
    formatType: MetricFormat.INTEGER,
    shortName: "Item-list click events",
    description:
      "The number of times users clicked an item when it appeared in a list. This metric counts the occurrence of the select_item event.",
  },
  itemListClickthroughRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Item list clickthrough rate",
    description:
      "The number of users who selected a list(s) divided by the number of users who viewed the same list(s).",
    summable: false,
  },
  itemListViewEvents: {
    formatType: MetricFormat.INTEGER,
    shortName: "Item-list view events",
    description:
      "The number of times the item list was viewed. This metric counts the occurrence of the view_item_list event.",
    summable: false,
  },
  itemPromotionClickthroughRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Item promotion clickthrough rate",
    description:
      "The number of users who selected a promotion(s) divided by the number of users who viewed the same promotion(s).",
    summable: false,
  },
  itemViewEvents: {
    formatType: MetricFormat.INTEGER,
    shortName: "Item view events",
    description:
      "The number of times the item details were viewed. The metric counts the occurrence of the view_item event.",
  },
  itemRefundAmount: {
    formatType: MetricFormat.MONETARY,
    shortName: "Item refund amount",
    description:
      "Item refund amount is the total refunded transaction revenue from items only. Item refund amount is the product of price and quantity for the refund event.",
  },
  itemRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Item revenue",
    description:
      "The total revenue from purchases minus refunded transaction revenue from items only. Item revenue is the product of its price and quantity. Item revenue excludes tax and shipping values; tax & shipping values are specified at the event and not item level.",
  },
  itemsAddedToCart: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items added to cart",
    description:
      "The number of units added to cart for a single item. This metric counts the quantity of items in add_to_cart events.",
  },
  itemsCheckedOut: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items checked out",
    description:
      "The number of units checked out for a single item. This metric counts the quantity of items in begin_checkout events.",
  },
  itemsClickedInList: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items clicked in list",
    description:
      "The number of units clicked in list for a single item. This metric counts the quantity of items in select_item events.",
  },
  itemsClickedInPromotion: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items clicked in promotion",
    description:
      "The number of units clicked in promotion for a single item. This metric counts the quantity of items in select_promotion events.",
  },
  itemsPurchased: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items purchased",
    description:
      "The number of units for a single item included in purchase events. This metric counts the quantity of items in purchase events.",
  },
  itemsViewed: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items viewed",
    description:
      "The number of units viewed for a single item. This metric counts the quantity of items in view_item events.",
  },
  itemsViewedInList: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items viewed in list",
    description:
      "The number of units viewed in list for a single item. This metric counts the quantity of items in view_item_list events.",
  },
  itemsViewedInPromotion: {
    formatType: MetricFormat.INTEGER,
    shortName: "Items viewed in promotion",
    description:
      "The number of units viewed in promotion for a single item. This metric counts the quantity of items in view_promotion events.",
  },
  organicGoogleSearchAveragePosition: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Organic Google Search average position",
    description:
      "The average ranking of your website URLs for the query reported from Search Console. For example, if your site's URL appears at position 3 for one query and position 7 for another query, the average position would be 5 (3+7/2). This metric requires an active Search Console link.",
    summable: false,
  },
  organicGoogleSearchClickthroughRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Organic Google Search clickthrough rate",
    description:
      "The organic Google Search click through rate reported from Search Console. Click through rate is clicks per impression. This metric requires an active Search Console link.",
    summable: false,
  },
  organicGoogleSearchClicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Organic Google Search clicks",
    description:
      "The number of organic Google Search clicks reported from Search Console. This metric requires an active Search Console link.",
  },
  organicGoogleSearchImpressions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Organic Google Search impressions",
    description:
      "The number of organic Google Search impressions reported from Search Console. This metric requires an active Search Console link.",
  },
  promotionClicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Promotion clicks",
    description:
      "The number of times an item promotion was clicked. This metric counts the occurrence of the select_promotion event.",
  },
  promotionViews: {
    formatType: MetricFormat.INTEGER,
    shortName: "Promotion views",
    description:
      "The number of times an item promotion was viewed. This metric counts the occurrence of the view_promotion event.",
  },
  publisherAdClicks: {
    formatType: MetricFormat.INTEGER,
    shortName: "Publisher ad clicks",
    description: "The number of ad_click events.",
  },
  publisherAdImpressions: {
    formatType: MetricFormat.INTEGER,
    shortName: "Publisher ad impressions",
    description: "The number of ad_impression events.",
  },
  purchaseToViewRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Purchase-to-view rate",
    description:
      "The number of users who purchased a product(s) divided by the number of users who viewed the same product(s).",
    summable: false,
  },
  purchaserRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "Purchaser rate",
    description:
      "The percentage of active users who made 1 or more purchase transactions.",
    summable: false,
  },
  refundAmount: {
    formatType: MetricFormat.MONETARY,
    shortName: "Refund amount",
    description:
      "The total refunded transaction revenues. Refund amount sums refunded revenue for the refund and app_store_refund events.",
  },
  scrolledUsers: {
    formatType: MetricFormat.INTEGER,
    shortName: "Scrolled users",
    description:
      "The number of unique users who scrolled down at least 90% of the page.",
    summable: false,
  },
  sessionsPerUser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Sessions per user",
    description:
      "The average number of sessions per user (Sessions divided by Active Users).",
    summable: false,
  },
  shippingAmount: {
    formatType: MetricFormat.MONETARY,
    shortName: "Shipping amount",
    description:
      "Shipping amount associated with a transaction. Populated by the shipping event parameter.",
  },
  taxAmount: {
    formatType: MetricFormat.MONETARY,
    shortName: "Tax amount",
    description:
      "Tax amount associated with a transaction. Populated by the tax event parameter.",
  },
  totalAdRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Total ad revenue",
    description:
      "The total advertising revenue from both Admob and third-party sources.",
  },
  totalPurchasers: {
    formatType: MetricFormat.INTEGER,
    shortName: "Total purchasers",
    description:
      "The number of users that logged purchase events for the time period selected.",
  },
  totalRevenue: {
    formatType: MetricFormat.MONETARY,
    shortName: "Total revenue",
    description:
      "The sum of revenue from purchases, subscriptions, and advertising (Purchase revenue plus Subscription revenue plus Ad revenue) minus refunded transaction revenue.",
  },
  transactionsPerPurchaser: {
    formatType: MetricFormat.DECIMAL,
    shortName: "Transactions per purchaser",
    description: "The average number of transactions per purchaser.",
    summable: false,
  },
  userEngagement: {
    formatType: MetricFormat.DURATION,
    shortName: "User engagement",
    description:
      "The total amount of time your website or app was in the foreground of users' devices.",
    summable: false,
  },
  userConversionRate: {
    formatType: MetricFormat.RELATIVE,
    shortName: "User conversion rate",
    description: "The percentage of users who triggered any conversion.",
    summable: false,
  },
  wauPerMau: {
    formatType: MetricFormat.RELATIVE,
    shortName: "WAU / MAU",
    description:
      "The rolling percent of 30-day active users who are also 7-day active users.",
    summable: false,
  },
} as const satisfies Record<string, MetricDetail>;

export type MetricKey = keyof typeof _metricDetails | CalculatedMetricKey;
export type AllMetrics = Record<MetricKey, number | string | undefined | null>;
export type StaticMetricKey = keyof typeof _metricDetails;

export const metricDetails = _metricDetails as Record<MetricKey, MetricDetail>;
export const MetricKeys = Object.keys(metricDetails) as MetricKey[];

export function getMetricDetail(
  keys: MetricKey[] | undefined,
  includeKey?: boolean,
): MetricDetail[] {
  if (keys) {
    return keys.map((key) => {
      if (includeKey) {
        return { ...metricDetails[key], key: key };
      }
      return metricDetails[key];
    });
  }
  return [];
}

export type ComparisonKey<T extends string> = `comparison${Capitalize<T>}`;

export function comparisonKey<T extends string>(key: T): ComparisonKey<T> {
  return `comparison${upperFirst(key)}`;
}

export function formatNumberByMetricFormat(
  type: MetricFormat,
  value: string | number | null | undefined,
  organization: Pick<Organization, "preferredCurrency"> | undefined | null,
  compact?: boolean,
  nullCase = "-",
): string {
  if (value === null) return nullCase;
  if (typeof value === "string") return value.toUpperCase(); // STATE type metrics
  if (type === MetricFormat.INTEGER) {
    return compact
      ? formatNumberCompact(value)
      : formatNumber(value, { maximumFractionDigits: 0 });
  } else if (type === MetricFormat.MONETARY) {
    return organization === null
      ? compact
        ? formatNumberCompact(value)
        : formatNumber(value, { maximumFractionDigits: 0 })
      : compact
      ? formatCurrencyCompact(value, organization?.preferredCurrency)
      : formatCurrency(value, organization?.preferredCurrency);
  } else if (type === MetricFormat.DECIMAL) {
    return compact
      ? formatNumberCompact(value, { maximumFractionDigits: 1 })
      : formatNumber(value, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
  } else if (type === MetricFormat.RELATIVE) {
    return compact
      ? formatNumberCompact(value, { style: "percent" })
      : formatNumber(value, { style: "percent", maximumFractionDigits: 2 });
  } else if (type === MetricFormat.DURATION) {
    return formatDuration(value);
  }
  assertUnreachable(`Unknown metric format: ${type}` as never);
}

export function formatMetric(
  key: MetricKey,
  value: string | number | null | undefined,
  organization: Pick<Organization, "preferredCurrency"> | undefined | null,
  compact?: boolean,
  nullCase = "-",
  metricDetail?: DynamicMetricDetails,
): string {
  if (value === null) return nullCase;
  if (key === "qualityScore") return value ? value + "/10" : "No data";

  const type: MetricDetail["formatType"] =
    metricDetail?.[key]?.formatType ||
    metricDetails[key]?.formatType ||
    MetricFormat.INTEGER;

  return formatNumberByMetricFormat(
    type,
    value,
    organization,
    compact,
    nullCase,
  );
}

export interface InjectedMetricSet {
  name: string;
  metrics: MetricKey[];
}

export enum FilterConditionFieldNames {
  EXACT = "exact",
  BELOW = "below",
  MAX = "max",
  MIN = "min",
  ABOVE = "above",
  EXCLUDE = "exclude",
}

export const FilterConditionLookup: Record<FilterConditionFieldNames, string> =
  {
    [FilterConditionFieldNames.EXACT]: "equal to",
    [FilterConditionFieldNames.BELOW]: "less than",
    [FilterConditionFieldNames.MAX]: "less than or equal to",
    [FilterConditionFieldNames.MIN]: "greater than or equal to",
    [FilterConditionFieldNames.ABOVE]: "greater than",
    [FilterConditionFieldNames.EXCLUDE]: "not equal to",
  };

export interface MetricFilterSet {
  metric: MetricKey | undefined;
  relationship: FilterConditionFieldNames | undefined;
  value: number;
}
