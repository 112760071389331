<template>
  <Menu v-slot="{ open }" as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex w-full flex-row content-center whitespace-nowrap rounded-md px-1.5 py-3 tracking-wide transition duration-300 hover:text-white focus:outline-none md:w-auto md:py-2 md:hover:bg-burgundy-700"
        :class="
          intersectedParentRoutes.length > 0
            ? 'md:bg-burgundy-800 text-purple-100 md:focus:bg-burgundy-700'
            : 'text-gray-200 md:focus:bg-burgundy-700'
        "
      >
        <slot name="avatar"></slot>
        {{ text }}
        <Icon
          name="angle-down"
          class="-mb-0.5 ml-0.5 h-4 w-4 self-center transition"
          :class="{ 'rotate-180': open }"
        />
      </MenuButton>
    </div>
    <Transition name="fade-100">
      <MenuItems
        :style="{ '--max-rows': maxRows }"
        class="relative z-40 grid auto-cols-[25rem] rounded-md bg-transparent shadow-xl ring-0 ring-gray-300 focus:outline-none md:absolute md:mt-2 md:w-max md:grid-flow-col md:grid-rows-[repeat(var(--max-rows),min-content)] md:bg-white md:ring-1"
        :class="[
          direction === 'right'
            ? 'left-0 origin-top-right'
            : 'right-0 origin-top-left',
        ]"
      >
        <RouterLink
          v-for="(link, i) in listedLinks"
          :key="String(link.name)"
          v-slot="{ href, navigate }"
          custom
          :to="link"
        >
          <MenuItem
            v-slot="{ active, close }"
            :href="href"
            :disabled="routeDisabledDueToAccessTier(link, organization.data)"
            @click="navigate"
          >
            <a
              :class="{
                'md:bg-gray-200': isLinkRouteActive(link.name),
                'pointer-events-none': routeDisabledDueToAccessTier(
                  link,
                  organization.data,
                ),
                'outline-none': active,
                // There's no first/last grid row/column CSS selector,
                // so we have to do some math.
                // First column, first row
                'md:rounded-tl-md': i === 0,
                // Last column, first row
                'md:rounded-tr-md':
                  i === maxRows * (Math.ceil(listedLinks.length / maxRows) - 1),
                // First column, last row
                'md:rounded-bl-md':
                  i === Math.min(maxRows - 1, listedLinks.length - 1) &&
                  !(i === listedLinks.length - 1 && $slots['extra-items']),
                // Last column, last row
                'md:rounded-br-md':
                  i === listedLinks.length - 1 && !$slots['extra-items'],
                // First row
                'md:pt-3': i % maxRows === 0,
                // Last row
                'md:pb-3':
                  i % maxRows === maxRows - 1 &&
                  !(i === listedLinks.length - 1 && $slots['extra-items']),
              }"
              class="flex rounded-md px-4 py-2 transition duration-300 first:pt-3 last:pb-3 hover:bg-burgundy-50 md:rounded-none"
              @click="toCloseMenu(close)"
            >
              <Icon
                :name="link.meta?.icon"
                class="mt-1 hidden h-5 w-6 md:block"
                :class="
                  routeDisabledDueToAccessTier(link, organization.data)
                    ? 'text-gray-500/50'
                    : 'text-gray-700'
                "
              />
              <div class="w-full md:ml-4">
                <div class="flex w-full justify-between">
                  <span
                    :class="
                      routeDisabledDueToAccessTier(link, organization.data)
                        ? 'md:text-gray-500/50 text-gray-50'
                        : 'md:text-gray-700 text-white opacity-80 md:opacity-100'
                    "
                    >{{
                      link.meta?.text ?? link.meta?.title ?? link.name
                    }}</span
                  >
                  <FeatureLabel :route="link" />
                </div>
                <p
                  class="mt-1 hidden font-sans text-sm text-gray-700 md:block"
                  :class="{
                    'opacity-50': routeDisabledDueToAccessTier(
                      link,
                      organization.data,
                    ),
                    'md:min-h-10': listedLinks.length > 3,
                  }"
                >
                  {{ link.meta?.description || "" }}
                </p>
              </div>
            </a>
          </MenuItem>
        </RouterLink>
        <slot name="extra-items" />
      </MenuItems>
    </Transition>
  </Menu>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import Icon from "@/components/Core/Icon.vue";
import { useRoute, RouteRecordRaw } from "vue-router";
import { routeDisabledDueToAccessTier } from "@/utils/tiers";
import FeatureLabel from "@/components/Core/FeatureLabel.vue";
import { useOrganizationQuery } from "@/services/organization";
import * as lodash from "lodash-es";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { useGetRouteAccess } from "@/utils/routes";
import { RouteRecordName } from "vue-router";

const props = defineProps({
  links: {
    type: Array as PropType<RouteRecordRaw[]>,
    required: true,
  },
  text: {
    type: String,
    default: "",
  },
  direction: {
    type: String,
    default: "right",
  },
  maxRows: {
    type: Number,
    default: undefined,
  },
});
const emit = defineEmits({
  "hide-menu": () => true,
});
const organization = useOrganizationQuery();

const route = useRoute();
const linkNames = computed(() => {
  return props.links.map((l) => {
    if (l.name) return l.name;
  });
});
function toCloseMenu(close: () => void) {
  emit("hide-menu");
  close();
}

const intersectedParentRoutes = computed(() => {
  // Intersects the links in the dropdown with the links in the route-tree to see
  // if we're on a child route of what the navbar-link leads to, so we can highlight
  // the dropdown button even if we're far down the route tree of e.g. /recommendations/automations/...
  return lodash.intersection(
    linkNames.value,
    route.matched.map((r) => r.name),
  );
});

function isLinkRouteActive(linkName: RouteRecordName | undefined): boolean {
  return route.matched.map((r) => r.name).includes(linkName);
}

const getRouteAccess = useGetRouteAccess();

const listedLinks = computed(() => {
  return props.links.filter((link) => {
    return getRouteAccess(link).show && !link.meta?.excludeFromNavbar;
  });
});

const maxRows = computed(() => props.maxRows ?? props.links.length);
</script>
