import { RouteRecordRaw } from "vue-router";

export const publicDashboardRoute: RouteRecordRaw[] = [
  {
    path: "shared-dashboard/:pk",
    name: "shared-dashboard",
    meta: {
      title: "Shared Dashboard",
      includeNavbar: false,
      requiresAuth: false,
    },
    component: () => import("@/views/Dashboard/SharedDashboard.vue"),
  },
];

export const addResourceRoute: RouteRecordRaw = {
  path: "add-account",
  name: "add-resource",
  redirect: { name: "select-channel" },
  meta: {
    title: "Add Account",
    text: "Add new account",
    description: "Connect new accounts to your organization",
    icon: "plus",
  },
  component: () => import("@/views/OrganizationSettings/AddResource.vue"),

  children: [
    {
      path: "select-channel",
      name: "select-channel",
      meta: { title: "Select Channel" },
      component: () =>
        import(
          "@/views/OrganizationSettings/AddResourceMenuSteps/SelectChannelStep.vue"
        ),
    },
    {
      path: "xandr-auth",
      name: "xandr-auth",
      meta: { title: "Xandr Auth" },
      component: () =>
        import(
          "@/views/OrganizationSettings/AddResourceMenuSteps/XandrAuthStep.vue"
        ),
    },
    {
      path: "kobler-auth",
      name: "kobler-auth",
      meta: { title: "Kobler Auth" },
      component: () =>
        import(
          "@/views/OrganizationSettings/AddResourceMenuSteps/KoblerAuthStep.vue"
        ),
    },
    {
      path: "c-alt-auth",
      name: "c-alt-auth",
      meta: { title: "C·ALT Auth" },
      component: () =>
        import(
          "@/views/OrganizationSettings/AddResourceMenuSteps/CAltAuthStep.vue"
        ),
    },
  ],
};

const tryUsers = [
  340, 486, 536, 347, 342, 515, 339, 487, 512, 817, 599, 757, 646, 516, 987,
  337, 1050, 980, 746, 986, 927, 562, 385, 882, 539, 972, 341, 1058, 788, 756,
  739, 816, 620, 1052, 360, 976, 1036, 1035, 933, 735, 884, 511,
];
const calibrateUsers = [808, 810, 823];
const norr3Users = [1032, 1034, 1046, 1051, 1045, 1134, 1033, 1135];
const atypUsers = [719, 715, 728, 721, 720, 718, 747];
const octiUsers = [1053, 1054];

export const organizationRoutes: RouteRecordRaw[] = [
  // !!! IMPORTANT !!!
  // Remember to change redirect URLs in the deployment repo env if changing these URLs
  {
    path: "general",
    name: "general",
    meta: { title: "Organization Settings" },
    component: () =>
      import("@/views/OrganizationSettings/OrganizationSettings.vue"),
  },
  {
    path: "tags",
    name: "tags",
    meta: { title: "Tag Management" },
    component: () => import("@/views/OrganizationSettings/TagManagement.vue"),
  },
  {
    path: "dashboards",
    redirect: { name: "dashboard-overview" },
    children: [
      {
        path: "overview",
        redirect: { name: "dashboard-overview" },
      },
      {
        path: "new",
        redirect: { name: "new-dashboard" },
      },
      {
        path: ":pk",
        redirect: { name: "edit-dashboard" },
      },
    ],
  },
  {
    path: "channels",
    name: "resources",
    meta: { title: "Channels" },
    component: () => import("@/views/OrganizationSettings/Resources.vue"),
  },
  addResourceRoute,
  {
    path: "edit-conversions",
    name: "edit-conversions",
    meta: { title: "Edit conversions" },
    component: () =>
      import("@/views/OrganizationSettings/Conversions/EditConversions.vue"),
  },
  {
    path: "calculated-metrics",
    name: "calculated-metrics",
    // TODO: Remove public prop once calc. metrics for Ad Performance is done
    meta: {
      title: "Calculated Metrics",
      public: false,
      externalUsersWithAccess: [
        ...tryUsers,
        ...calibrateUsers,
        ...norr3Users,
        ...atypUsers,
        ...octiUsers,
      ],
    },
    component: () =>
      import(
        "@/views/OrganizationSettings/CalculatedMetrics/CalculatedMetrics.vue"
      ),
  },
  {
    path: "changelog",
    name: "changelog",
    meta: { title: "Changelog" },
    component: () => import("@/views/OrganizationSettings/Changelog.vue"),
  },
];
